import styled from "styled-components"

export const Box = styled.div`
  position: absolute;
  background: var(--gradientlight);
  width: 100vw;
  height: 700px;
  border-radius: 0 0 70px 0;
  top: 0;
  left: 0;
  z-index: -1;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5);

  @media screen and (min-width: 1550px) {
    /* padding-top: 70px; */

    width: 80%;
    height: 900px;
    border-radius: 0 0 45px 0;
  }
`
